import { ArbitrumOne, ArbitrumSepolia } from "../model/chain/ethereum"; // @notice: See if these numbers need to be updated

export var getBlocksPerFetchForChainId = chainId => {
  switch (chainId) {
    case ArbitrumOne.chainId:
      return 5;

    case ArbitrumSepolia.chainId:
      return 20;

    default:
      return 1;
  }
};