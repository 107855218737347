import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';
import { axios } from "../apis/common";
import { API_ROOT_DOMAIN } from "../constants/rootDomain";
import { useUpdaterChainId } from "./web3";
export var BestBuyStatus;

(function (BestBuyStatus) {
  BestBuyStatus["Pending"] = "pending";
  BestBuyStatus["Fulfilled"] = "fulfilled";
  BestBuyStatus["PartialFilled"] = "partialfilled";
  BestBuyStatus["Cancelled"] = "cancelled";
  BestBuyStatus["Expired"] = "expired";
  BestBuyStatus["PartialFillExpired"] = "partialfillexpired";
  BestBuyStatus["Insufficient"] = "insufficient";
})(BestBuyStatus || (BestBuyStatus = {}));

export var BestBuyTradeStatus;

(function (BestBuyTradeStatus) {
  BestBuyTradeStatus["Signed"] = "signed";
  BestBuyTradeStatus["Success"] = "success";
  BestBuyTradeStatus["Failed"] = "failed";
  BestBuyTradeStatus["TradeRateInvalid"] = "traderateinvalid";
  BestBuyTradeStatus["Insufficient"] = "insufficient";
  BestBuyTradeStatus["DealerSigInvalid"] = "dealersiginvalid";
  BestBuyTradeStatus["UserNotApprove"] = "usernotapprove";
  BestBuyTradeStatus["AllowanceInsufficient"] = "allowanceinsufficient";
  BestBuyTradeStatus["NotReached"] = "notreached";
})(BestBuyTradeStatus || (BestBuyTradeStatus = {}));

export var useUserBestBuyList = status => {
  var _useWeb3React = useWeb3React(),
      account = _useWeb3React.account;

  var chainId = useUpdaterChainId();

  var getKey = (pageIndex, previousPageData) => {
    if (!account) return null; // 已经到最后一页

    if (previousPageData && !previousPageData.length) return null;
    var baseUrl = "https://api.".concat(API_ROOT_DOMAIN, "/v5/best-buy/bestbuy?status=").concat(status.join(','), "&user=").concat(account, "&chainId=").concat(chainId, "&pageSize=6");
    if (pageIndex === 0) return baseUrl;
    var cursor = previousPageData[previousPageData.length - 1].orderHash;
    return "".concat(baseUrl, "&before=").concat(cursor);
  };

  var _useSWRInfinite = useSWRInfinite(getKey, url => axios.get(url)),
      data = _useSWRInfinite.data,
      error = _useSWRInfinite.error,
      size = _useSWRInfinite.size,
      setSize = _useSWRInfinite.setSize;

  var hasNextPage = useMemo(() => {
    if (data) {
      var lastDataLength = data[data.length - 1].length;

      if (lastDataLength === 0 || lastDataLength < 6) {
        return false;
      }
    }

    return true;
  }, [data]);
  var mergedArray = !!data ? [].concat(...data) : undefined;

  if (mergedArray) {
    mergedArray.forEach(item => {
      item.progress = '0';

      if (item.trades && item.trades.length > 0) {
        var filledTrades = item.trades.filter(t => t.status === 'success');
        var filledDealerAmount = filledTrades.reduce((prev, curr) => prev.plus(curr.dealerTokenFilledAmount || '0'), new BigNumber(0));
        var filledUserAmount = filledTrades.reduce((prev, curr) => prev.plus(curr.userTokenFilledAmount || '0'), new BigNumber(0));
        var progress = filledDealerAmount.multipliedBy(100).dividedBy(item.dealerTokenAmount).toFormat(2);
        item.progress = progress;
        item.filledUserAmount = filledUserAmount.toString();
        item.filledDealerAmount = filledDealerAmount.toString();
      }
    });
  }

  return {
    data: mergedArray,
    hasNextPage,
    size,
    setSize,
    error
  };
};