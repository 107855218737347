import { ENV } from "../constants/env";
import { ArbitrumOne, ArbitrumRinkeby, ArbitrumSepolia, Mainnet, Sepolia } from "../model/chain/ethereum";
import { useLimitOrderRouter } from "./useLimitOrderRouter";
import { useBestBuyRouter } from "./useBestBuyRouter";
import { useNetworkValidator } from "./web3"; // 保证用于 updater 的 chainId 永远是对的，和用户的 chainId 无关

export var useUpdaterChainId = () => {
  var isLimitOrderRouter = useLimitOrderRouter();
  var isBestBuyRouter = useBestBuyRouter();

  var _useNetworkValidator = useNetworkValidator(),
      isArbitrum = _useNetworkValidator.isArbitrum;

  var isDev = ENV.development || ENV.local;

  if (isLimitOrderRouter && isArbitrum) {
    return isDev ? ArbitrumRinkeby.chainId : ArbitrumOne.chainId;
  } else if (isBestBuyRouter) {
    if (isArbitrum) return isDev ? ArbitrumSepolia.chainId : ArbitrumOne.chainId;
    return isDev ? Sepolia.chainId : ArbitrumOne.chainId;
  }

  return isDev ? Sepolia.chainId : Mainnet.chainId;
};