import { useWeb3React } from '@web3-react/core';
import { ArbitrumOne, ArbitrumRinkeby, ArbitrumGoerli, ArbitrumSepolia } from "../model/chain/ethereum";
export var useNetworkValidator = () => {
  var _useWeb3React = useWeb3React(),
      chainId = _useWeb3React.chainId;

  var ArbChainIds = [ArbitrumOne.chainId, ArbitrumRinkeby.chainId, ArbitrumGoerli.chainId, ArbitrumSepolia.chainId];
  var isArbitrum = ArbChainIds.includes(chainId);
  return {
    isArbitrum
  };
};