var zh_CN = {
  activity: {
    // 彩蛋
    congratulation: '恭喜你',
    open_hidden_egg: '开启隐藏「彩蛋」',
    unfortunately: '很遗憾',
    you_have_no_prize: '你还未有缘遇到「彩蛋」喔！',
    get_prize_by_tokenlon: '通过 Tokenlon 完成交易就有机会获得「彩蛋」，快去试试吧',
    exchange_now: '前往 Tokenlon',
    // 为何中奖
    why_got_prize: '为何中奖',
    the_transaction: '你的该笔',
    tokenlon_tx: 'Tokenlon 交易',
    blockhash_matched: 'blockhash 命中特殊数值，将获得盲盒奖励！ ',
    dforce_ad: 'Lendf.Me将于近期上线，',
    dforce_ad2: '即将开启USDx的生息和借贷功能！',
    more_details: '了解更多',
    exchange: '前往兑换',
    congratulations: '恭喜你！获得盲盒奖励，价值',
    cost: '$s 美元',
    pax_notice_1: '1 PAXG = 31.1g 黄金',
    pax_notice_2: 'PAXG 是锚定黄金的稳定币',
    imbtc_notice_1: '1 imBTC = 1 BTC',
    imbtc_notice_2: '价值相同，随存随取',
    // 活动规则和介绍
    desc_title: 'Tokenlon 交易盲盒',
    activity_period: '活动周期',
    activity_period_detail: '2019.11.11（UTC+8） - 2019.11.13 12:00 PM（UTC+8）',
    activity_endtime: '活动结算时间',
    activity_endtime_detail: '每周五中午 12:00',
    activity_rules: '活动规则',
    activity_rules_detail: '根据交易所在区块哈希中「1」的个数来决定盲盒的价值',
    activity_notes: '注意事项',
    activity_notes_detail_1: '盲盒数量有限，先到先得，送完为止，如全部派发完，活动提前结束；',
    activity_notes_detail_2: '奖励会自动转入发起闪兑的钱包地址，请耐心等待；',
    activity_notes_detail_3: '活动最终解释权归 Tokenlon 以及 imToken 所有。',
    desc_title_light: 'Tokenlon 正式上线！称霸榜单赢好礼！',
    activity_rules_detail_light: '每周登上交易 Top 3 的用户将获得丰厚好礼！快来称霸榜单吧！',
    activity_reward: '本周活动奖励',
    activity_reward_1: 'Top 1: 1000 USDx',
    activity_reward_2: 'Top 2: 500 USDx',
    activity_reward_3: 'Top 3: 300 USDx'
  },
  blockHash: {
    title: '开启彩蛋',
    // 彩蛋
    congratulation: '恭喜你',
    open_hidden_egg: '开启隐藏「彩蛋」',
    unfortunately: '很遗憾',
    you_have_no_prize: '你还未有缘遇到「彩蛋」喔！',
    support_by: '由 $s 提供',
    get_prize_by_tokenlon: '通过 Tokenlon 完成交易就有机会获得「彩蛋」，快去试试吧',
    exchange_now: '前往 Tokenlon',
    // 为何中奖
    why_got_prize: '为何中奖',
    the_transaction: '你的该笔',
    tokenlon_tx: 'Tokenlon 交易',
    blockhash_matched: 'blockhash 命中特殊数值，将获得彩蛋奖励！ ',
    dforce_ad: 'Lendf.Me将于近期上线，',
    dforce_ad2: '即将开启USDx的生息和借贷功能！',
    exchange: '前往兑换',
    // 活动规则和介绍
    desc_title: 'Tokenlon 交易彩蛋',
    activity_period: '活动周期',
    activity_period_detail: '07/31/2019 (GMT+8) ～ 08/30/2019 12:00 (GMT+8)',
    activity_endtime: '活动结算时间',
    activity_endtime_detail: '每周五中午 12:00',
    activity_rules: '活动规则',
    activity_rules_detail_1: '1. 活动期间，Tokenlon 交易所在的区块哈希命中「彩蛋」规则，奖励 5 USDx！',
    activity_rules_detail_2: '2. 奖品将空投至发起该笔交易的钱包地址，请注意查收',
    desc_title_light: 'Tokenlon 正式上线！称霸榜单赢好礼！',
    activity_rules_detail_light: '每周登上交易 Top 3 的用户将获得丰厚好礼！快来称霸榜单吧！',
    activity_reward: '本周活动奖励',
    activity_reward_1: 'Top 1: 1000 USDx',
    activity_reward_2: 'Top 2: 500 USDx',
    activity_reward_3: 'Top 3: 300 USDx'
  },
  tradeData: '交易数据',
  developer_document: '开发者',
  audit_report: '审计报告',
  instant_exchange: '闪兑',
  cancel: '取消',
  confirm: '确认',
  please_open_in_imtoken: '请在 imToken 中打开',
  update_app_title: '升级至 imToken 最新版本',
  update_app_desc: '由于当前 imToken 版本过低，无法使用该应用。请使用系统浏览器访问 https://token.im 升级 imToken。',
  no_wallet_title: '未发现钱包',
  no_wallet_desc: '请检查',
  wallet_address: '钱包地址',
  exchange_times: '交易笔数',
  exchange_amount: '交易量',
  weekly_exchange: '近七日交易数据',
  weekly_top: '本周交易量 Top 5',
  seven_days: '七日',
  wallet_amount: '交易用户',
  date: '日期',
  sponsor: '活动赞助商',
  leaderboard: 'Tokenlon 数据榜单',
  no_result: '暂无交易数据',
  zh: '中文',
  slogan_1: 'Aggregate optimal prices,',
  slogan_2: 'deliver reliable experience',
  slogan_desc: 'Tokenlon - 值得信赖的去中心化交易结算协议',
  launch_dapp: '启动应用',
  trade_in_imtoken: '使用 imToken 交易',
  feature_title: '探索并体验可靠的产品功能',
  feat1_title: '闪兑',
  feat1_desc: '无需充提，以更好的深度和价格一键兑换主流代币',
  feat1_button: '立即兑换',
  feat2_title: '限价单',
  feat2_desc: '挂单交易，无需盯盘，限时 0 手续费和矿工费',
  feat2_button: '立即体验',
  feat3_title: 'LON 经济代币',
  feat3_desc: 'LON 持有者能参与社区治理投票和代币质押，也能享有低至 0.1% 的交易费率优惠',
  feat3_button: '参与质押',
  feat4_title: 'BTC 跨链网关服务',
  feat4_desc: '一键完成 BTC 到 WBTC 或 imBTC 的兑换',
  feat4_button: '了解更多',
  monthly_transactions: '交易笔数',
  monthly_volume: '交易量',
  monthly_user: '用户量',
  total_volume: '累计交易额',
  total_transactions: '交易笔数',
  total_wallets: '交易地址数',
  total_order_success_rate: '订单成功率',
  benefits_title: '以太坊上可靠的兑换服务',
  benefit1_title: '免矿工费之忧',
  benefit1_desc: '没有 ETH 支付矿工费？不要紧，你依旧可以在 Tokenlon 进行闪兑交易',
  benefit2_title: '最优报价',
  benefit2_desc: '对比专业做市商 (PMM) 和自动做市商（AMM）报价，为你提供最优价格',
  benefit3_title: '安全至上',
  benefit3_desc: '每一次的合约代码更新都经过知名安全机构审计，确保合约安全性',
  benefit4_title: '高成功率',
  benefit4_desc: '为了给你最好的兑换体验，我们努力将订单成功率保持在 99% 以上',
  benefit5_title: '交易奖励',
  benefit5_desc: '为了鼓励用户进行交易，每笔成功的交易都能获得 LON 奖励来降低你的矿工费成本',
  benefit6_title: '专业的用户支持',
  benefit6_desc: '需要帮助？通过 support@tokenlon.im 与我们的专业用户支持团队联系吧',
  services_title: '更多服务与价值介绍',
  service1_title: '限价单',
  service1_desc: 'Layer 2 生态不断壮大，除了可以在以太坊网络使用「限价单」功能，你同样可以在 Arbitrum 网络进行挂单交易。',
  service1_link: '了解更多',
  service2_title: 'LON 经济模型',
  service2_desc: 'LON 是 Tokenlon 发行的应用型代币，用于激励生态中各方并确保其能够协力推动生态发展',
  service2_link: '了解更多',
  service2_link_2: '阅读白皮书',
  service3_title: 'imToken 内交易',
  service3_desc: 'Tokenlon 已深度集成在世界领先的去中心化钱包 imToken App 内，你可以随时随地进行交易',
  service3_link: '下载 imToken',
  how_it_works_title: '如何使用 imToken 进行交易',
  work_title1: '链下报价',
  work_desc1: '做市商即时提供最优报价',
  work_title2: '链上成交',
  work_desc2: '基于 0x 协议，借助区块链智能合约完成币币兑换',
  work_title3: '极速兑换',
  work_desc3: '快速完成交易，即时到账，闪电般高效',
  view_documents: '查看文档',
  docs_title: '开发者资源',
  doc1_title: 'Tokenlon 智能合约',
  doc1_desc: '阅读 Tokenlon 相关合约，了解协议是如何运作的',
  doc2_title: 'Tokenlon SDK',
  doc2_desc: '让更多的协议轻松获得 Tokenlon 的询价、订单与结算能力',
  doc3_title: '做市商文档',
  doc3_desc: '想成为 Tokenlon 的生态里的重要一员吗？这份做市商接入指引助你快速加入',
  subscribe_newsletter: '订阅 Tokenlon 资讯，获取每周最新动态',
  enter_email_address: '输入你的邮箱地址',
  download_imtoken: '下载 imToken！',
  imtoken_desc: '便捷管理多链资产，高效完成去中心化币币兑换，更丰富的 DApp 浏览器',
  developer_docs: 'Tokenlon MMSK',
  imtoken_2: 'imToken 2.0',
  business: '商务合作',
  resource: '资源',
  contact: '联系',
  social: '社区',
  footer_support: '支持',
  media_twitter: 'Twitter',
  media_github: 'GitHub',
  media_medium: 'Medium',
  media_discord: 'Discord',
  media_zendesk: '帮助中心',
  gov_forum: '治理论坛',
  snapshot: '提案',
  data: '数据',
  data_subtitle: '月度',
  // metadata deprecated
  title: 'Tokenlon 官方网站 | 值得信赖的去中心化交易所， 基于 0x 协议',
  keywords: '区块链，数字货币，数字资产，投资，钱包，以太坊，比特币，USDT, EOS，Cosmos，Staking，voting，Tokenlon，imBTC，官网',
  description: '值得信赖的去中心化交易所，基于 0x 协议。支持交易 BTC, ETH, EOS, Cosmos ATOMs, DAI 等代币',
  generator: '无需充提、资产自持，一键体验最优价格、滑点小的兑换，订单成功率 99% 以上',
  // metadata
  meta_common_title: 'Tokenlon 协议 | 给你最优价格的去中心化兑换体验',
  meta_common_description: '无需充提、资产自持，一键体验最优价格、滑点小的兑换，订单成功率 99% 以上',
  meta_common_keywords: 'tokenlon, LON 交易, 去中心化交易所, dapp 币对币闪兑',
  meta_exchange_title: 'Tokenlon 闪兑',
  meta_exchange_description: '符合 Tokenlon 协议流动性需求的代币你都可以进行兑换，无需充提、资产自持，一键体验最优价格、滑点小的兑换',
  meta_exchange_keywords: '去中心化交易所, 币对币闪兑, lon 交易所, 去中心化币对币闪兑',
  meta_limit_order_title: 'Tokenlon 限价单',
  meta_limit_order_description: 'Tokenlon 限价单帮助你更好地抓住市场变动机会，无需盯盘，订单达到市价即可自动成交',
  meta_limit_order_keywords: 'arbitrum 去中心化交易所, arbitrum 交易所, arbitrum 闪兑, 有限价单的去中心化交易所',
  meta_lon_about_title: 'LON 代币',
  meta_lon_about_description: 'LON 是 Tokenlon 发行的应用型代币，用于激励生态中各方并确保其能够协力推动生态发展。持币者能享受费率优惠以及参与社区治理投票的权利',
  meta_lon_keywords: 'LON 币, LON 网络, lon, lon 是什么, tokenlon 币',
  meta_lon_dashboard_title: 'LON 看板',
  meta_lon_dashboard_description: '在这里，你可以看到所有有关 Tokenlon 和 LON 相关数据，比如 24h Tokenlon 交易量、LON 持币地址数 和 LON 流通情况等',
  meta_lon_trade_title: 'LON 交易挖矿',
  meta_lon_trade_description: '展示当前交易挖矿奖池情况，同时你可以查看历史挖矿奖励详情',
  meta_lon_liquidity_title: 'LON 流动性挖矿',
  meta_lon_liquidity_description: '为 LON 在 AMM 池中提供流动性并参与流动性挖矿获得奖励',
  meta_lon_staking_title: 'LON 质押',
  meta_lon_staking_description: '参与 LON 质押的用户将会质押者根据质押的 LON 数量按比例获得质押奖励。质押奖励来自于协议在公开市场上回购的 LON。根据 LON 经济模型，60% 的协议捕获的手续费将会用户回购 LON 并分发给参与 LON 质押用户',
  meta_lon_buyback_title: 'LON 回购',
  meta_lon_buyback_description: 'Tokenlon 协议所收取的手续费将会被用于在公开市场上回购 LON，回购的 LON 将被转移至财库以及用于质押奖励',
  meta_blog_title: 'Tokenlon 博客',
  meta_blog_description: '了解更多 Tokenlon 官方资讯与最新动态，向你提供每周关于 Tokenlon 产品相关更新以及市场活动预告',
  meta_imbtc_title: 'imBTC',
  meta_imbtc_description: 'imBTC 是与 BTC 1:1 锚定的 ERC-777 形式的代币，可使用 BTC 一键在 Tokenlon 进行认购并参与兑换',
  meta_imbtc_keywords: 'imbtc, 比特币',
  meta_imbtc_dashboard_title: 'imBTC 数据看板',
  meta_imbtc_dashboard_description: '查看 imBTC 铸币数量以及赎回情况，持续验证 imBTC 铸币量与 BTC 托管量间的是 1:1 对应关系',
  latest_news: '最新动态',
  view_all: '阅读更多',
  verification: '验证邮箱',
  verify_failed: '无效，验证失败',
  verify_successful: '有效，验证成功',
  seven_days_roman: '7天',
  thirty_days_roman: '30天',
  tx_amount: '交易笔数',
  my_exchange_data: '我的交易数据',
  network_error: '网络繁忙，请刷新重试',
  tokenloN_exchange_data: 'Tokenlon 交易数据',
  top_traders: '累计交易 Top5',
  ranking: '排名',
  none: '无',
  support: '帮助中心',
  discover: ' 发现',
  wiki: '知识库',
  allowance_open_pending: '代币授权的交易确认中',
  allowance_close_pending: '取消代币授权的交易确认中',
  tokenlon_need_allowance: '交易前需要对该资产进行授权',
  tokenlon_set_allowance: '授权 {{ symbol }} 代币',
  pls_input_a_valid_amount: '请输入转出数量，开始兑换',
  $symbol_insufficient_balance: '{{symbol}} 余额不足',
  preview_$symbol_insufficient_balance: '{{symbol}} 余额不足',
  tokenlon_$token_more_than_sell_maximum_$value: '{{token}} 最大卖出数量 {{value}}',
  spotswap_$token_less_than_sell_minimum_$value: '代币支付矿工费功能已开启，当前交易对最小交易数量为 ',
  unvalid_input_amount: '请输入有效数字',
  tokenlon_$token_more_than_buy_maximum_$value: '{{token}} 最大买入数量 {{value}}',
  tokenlon_$token_less_than_buy_minimum_$value: '{{token}} 最小买入数量 {{value}}',
  maker_order_not_exist: '订单验证失败，请稍后重试(4001)',
  token_not_match: '订单验证失败，请稍后重试(4002)',
  INSUFFICIENT_MAKER_BALANCE: '订单验证失败，请稍后重试(4004)',
  symbol_insufficient_miner_fee: '矿工费不足',
  symbol_insufficient_balance_and_miner_fee: 'ETH 余额不足以支付转账金额和矿工费',
  invalid_nonce: '网络繁忙请稍后再试',
  tokenlon_found_pending_tx: '目前仍有交易等待打包，你可以选择加速或等待交易打包完成后再试',
  accelerate_tx: '加速交易',
  internet_disconnect_without_rate_updated: '网络不稳定，请稍后重试',
  can_not_solve_current_exchange: '暂时无法提供该数量的报价，请调整数量后重试',
  tokenlon_$token_more_than_$balance: '$s 余额不足',
  SIGNATURE_FAILED: '订单验证失败，请稍后重试(4003)',
  SANITY_FAILED: '报价验证失败，请稍后重试(2003)',
  BALANCE_FAILED: '暂无报价，请稍后重试(1002)',
  LONG_TIME_HOLD: '钱包地址存在异常行为，暂时无法交易。如有疑问请联系 bd@tokenlon.im(5002)',
  FROM_UPBIT: '钱包地址存在异常行为，暂时无法交易。如有疑问请联系 bd@tokenlon.im(5003)',
  PERMIT_CHECK_FAILED: '钱包地址存在异常行为，暂时无法交易。如有疑问请联系 bd@tokenlon.im(5001)',
  ESCAPE_DEALT_ORDER: '钱包地址存在异常行为，暂时无法交易。如有疑问请联系 bd@tokenlon.im(5004)',
  NO_DATA: '暂无报价，请稍后重试(1001)',
  SLP_FAILED: '报价验证失败，请稍后重试(2001)',
  tokenlon_network_request_failed: '网络请求失败，请稍后再试',
  tokenlon_tooltip_network_error: '网络连接不畅',
  tokenlon_tooltip_ok: '报价实时更新中',
  tokenlon_tooltip_quote_error: '暂无报价，稍后重试',
  reference_price: '参考报价',
  no_data: '暂无数据',
  tokenlon_exchange_pending: '打包中',
  tokenlon_exchange_broadcast: '已提交',
  exchange_success: '兑换成功',
  exchange_failed: '兑换失败',
  help_center: '帮助中心',
  tokenlon_leaderboard: 'Tokenlon 数据榜单',
  tokenlon_tutorial: '闪兑教程',
  agree_terms_notice: '点击兑换即代表同意',
  terms: '条款',
  amount_for_buy: '买入数量',
  amount_for_sell: '卖出数量',
  connect: '连接钱包',
  exchange: '闪电兑换',
  wallet: '钱包',
  tokenlon_records: '交易记录',
  allowance_manage: '管理代币授权',
  authorized_tokens: '管理代币授权',
  why_need_set_allowance: '了解合约详情',
  why_need_set_allowance_notice_1: 'Tokenlon 是基于以太坊智能合约的原子币币兑换系统，在兑换过程中，代币不会离开你的钱包，通过智能合约在区块链中完成原子交换。',
  why_need_set_allowance_notice_2: '授权是为了让 Tokenlon 的智能合约获得代币的转账权限，在用户发起交易后进行代币兑换。如果不授权就无法使用 Tokenlon 进行币币兑换。',
  set_allowance_step: '授权操作步骤：',
  set_allowance_step_notice_1: '打开授权开关',
  set_allowance_step_notice_2: '发送授权交易',
  set_allowance_step_notice_3: '等待交易成功',
  set_allowance_step_notice_4: '代币授权完成',
  set_allowance_warning: '注意',
  set_allowance_warning_1: '在 Tokenlon 中，ETH 兑换其他代币无需授权，使用 ERC20 代币兑换，需要授权',
  set_allowance_warning_2: '授权不会影响资产安全',
  metamask_notice_1: '尚未安装 MetaMask 请先安装',
  metamask_notice_2: '选择并连接钱包',
  install_metamask: '安装 MetaMask 钱包',
  connect_wallet: '连接钱包',
  metamask_install_btn: '安装',
  metamask_connect_btn: '连接',
  later_to_op: '稍后再说',
  expected_deal_time: '预计成交',
  rate: '汇率',
  limit_price: '挂单价',
  rate_unavailable: '暂无报价',
  charge: '手续费',
  placed_time: '下单时间',
  trade_detail: '交易详情',
  no_search_result: '未搜索到相关代币',
  no_search_result_desc: '代币未符合 Tokenlon 协议要求，<br/><1>了解 Tokenlon 代币白名单机制</1>',
  lo_no_search_result_desc_1: '请通过代币合约地址添加代币',
  lo_no_search_result_desc_2: '请确认代币合约地址是否有误以及是否在当前网络部署',
  import_token: '添加代币',
  search_token: '搜索代币名称或合约地址',
  search_token_or_address: '搜索名称或导入合约地址',
  search_by_token_address: '请输入代币合约地址',
  loading: '加载中',
  tokenlon_web_title: 'Tokenlon - 简单易用的去中心化交易所',
  tokenlon_web_description: '值得信赖的去中心化交易所，基于 0x 协议。支持交易 ETH 等代币',
  tokenlon_web_keywords: '去中心化交易所, 0x 协议, MetaMask, 以太坊, DEX, USDT, ETH',
  tokenlon_balance: '余额：',
  best_price: '最优报价',
  miner_fee: '矿工费',
  signing: '签名中',
  sending: '发送中',
  $token_empty_balance: '$s 余额为0',
  my_wallet: '我的钱包',
  wallet_connected: '已连接钱包',
  wallet_disconnected: '未连接钱包',
  connect_walllet: '连接钱包',
  trade_detail_fee: '费率',
  estimated_miner_fee: '预估矿工费：',
  trade_detail_charge: '手续费($s%)：',
  received_amount: '到账金额',
  fee_introduction: '费率介绍',
  connect_success: '连接成功',
  connect_dismiss: '连接已断开',
  developer: '开发者',
  links: '友情链接',
  current_browser_not_supported_title: '在 imToken App 内使用 Tokenlon',
  current_browser_not_supported_desc: '当前浏览器不支持钱包连接，为获取更好的体验，移动端请在 imToken 市场频道使用 Tokenlon 交易。',
  list_new_token: '上架币种提交',
  imbtc_slogan: '透明，才更值得信任',
  imbtc_slogan_desc: '你可以通过查询 imBTC 代币合约地址和 BTC 托管地址，了解每一个 imBTC 代币铸造、销毁、转账交易流程，保障 imBTC 的背后拥有1:1 BTC。',
  published: '已发行',
  holder: '持有者',
  deposit: '已托管',
  yearly_yield: '年化收益',
  imbtc_contract: 'imBTC 代币合约',
  imBTC: 'imBTC',
  imbtc_title: 'imBTC , 更好用的比特币',
  imbtc_subtitle: 'imBTC 具有智能合约的可互操作性，无缝融入去中心化交易和金融服务中，同时为以太坊生态注入强大的资产流动性。',
  get_imbtc: '获得 imBTC',
  learn_more: '了解更多',
  what_is_imbtc: '什么是 imBTC? ',
  imbtc_is: 'imBTC 通过 1:1 锚定 BTC 发行的以太坊 Token，持有者可以自由转账、赎回、兑换，持有 imBTC 期间还将获得 Tokenlon 平台的收入分成。',
  imbtc_feat1_title: '随存随取',
  imbtc_feat2_title: '持币生息',
  imbtc_feat3_title: '币币兑换',
  imbtc_feat1_subtitle: '你可以随时使用 BTC 或 ERC20 代币兑换 imBTC，也可以随时赎回你的 BTC。',
  imbtc_feat2_subtitle: '当你持有 imBTC 将会获得 Tokenlon DEX 购买 imBTC 的手续费分成，目前年化收益为 {{apr}}。',
  imbtc_feat3_subtitle: '你可以选择去中心化交易所进行 imBTC 兑换，比如 Tokenlon。',
  how_get_imbtc: '如何获得 imBTC',
  get_imbtc1: '使用 ERC 20 代币通过 Tokenlon 兑换 imBTC',
  get_imbtc2: '使用 BTC 通过 imBTC DApp 兑换 imBTC',
  get_imbtc_link: '获得 imBTC',
  faq: '常见问题',
  q1: '什么是 imBTC？',
  q2: 'imBTC 的发行和销毁原理？',
  q3: '如何获得 imBTC？',
  q4: '用户参与认购 imBTC 的 BTC 存放在哪里？是否安全？',
  q5: 'Tokenlon 如何保证 imBTC 具备流动性？',
  q6: '持有 imBTC 会获得哪些收益？',
  q7: '如何查看、派发收益？',
  q8: '卖出、转走 imBTC 是否还有收益？',
  a1: 'imBTC 是与 BTC 1:1 锚定的 ERC-777 代币，由 Tokenlon 负责发行和监管。Tokenlon 支持 imBTC 交易，持有 imBTC 能够获得收益（收益来自 Tokenlon 用户购买 imBTC 的交易手续费和用户赎回 BTC 的手续费）。',
  a2_1: 'imBTC 并不是随意发行的代币，而是通过「锁仓兑换」方式产生的。',
  a2_2: '将 BTC 转账到 Tokenlon 的安全账户锁定，能够获得同等数量的 imBTC。销毁 imBTC 就能赎回锁定的 BTC。这种方式能够保证 imBTC 与 BTC 是精确的 1:1 关系，同时区块链透明、可追溯的特点能够保证每个 imBTC 的发行和销毁都能被跟踪和验证。这些机制为 imBTC 的公开透明、不被超发滥发提供了保证。',
  a3_1: '参与 imBTC 认购：',
  a3_2: '用户可以打开市场中的「imBTC」DApp 参与 imBTC 认购，',
  a3_3: '查看认购教程',
  a3_4: '在 Tokenlon 兑换 imBTC：',
  a3_5: 'imBTC 认购结束后，Tokenlon 会开放 imBTC 交易对，用户可以使用 ETH/USDT 兑换 imBTC。',
  a4_1: 'BTC 将被保存在 Tokenlon 的安全冷钱包中，通过安全冷钱包来保证 BTC 资产的安全。',
  a5_1: 'imBTC 的流动性体现在以下两点：',
  a5_2: '1. 用户可以随时使用 imBTC 赎回自己的 BTC。',
  a5_3: '2. Tokenlon 支持 imBTC 交易对，用户可以随时将 imBTC 兑换为其它数字货币。',
  a6_1: '为了补偿质押 BTC 产生的流动性损失，Tokenlon 将以下面两种收入来源进行派息',
  a6_2: '1. Tokenlon  用户购买 imBTC 支付的手续费。',
  a6_3: '2. 用户赎回 BTC 支付的手续费。',
  a6_4: '派息将以上方说明的平台收入的 100% 开始然后逐年减半的方式进行，即：',
  a6_5: '发行日起第一年 100%',
  a6_6: '第二年 50%',
  a6_7: '第三年 25%',
  a6_8: '第四年 12.5% … 以此类推',
  a6_9: '也就是说，第一年 Tokenlon 会将「Tokenlon  用户购买 imBTC 支付的手续费+用户赎回 BTC 支付的手续费」的全部收入作为利息派发给 imBTC 持币用户，第二年 Tokenlon 会将「Tokenlon  用户购买 imBTC 支付的手续费+用户赎回 BTC 支付的手续费」收入的 50% 作为利息派发给 imBTC 持币用户，以此类推。',
  a6_10: '用户收益=（用户持有 imBTC 数量 / imBTC 总量） * 平台派发总收益，收益以 imBTC 的形式发放到用户的持币地址。',
  a7_1: '在 imBTC DApp 首页能看到个人历史累积收益。任何持有 imBTC 的用户都能点击「派息」来派发收益。',
  a7_2: '这里有两点需要注意：',
  a7_3: '1. 任何人都能派发收益，收益会自动发放到所有用户的 imBTC 钱包地址。',
  a7_4: '2. 派发收益要支付矿工费，发起者的钱包要有足够的 ETH。',
  a8_1: '由「用户收益=（用户持有 imBTC 数量 / imBTC 总量） * 交易手续费数量」可以得到结论，如果你的地址中没有 imBTC 将不会获得收益。',
  language: '语言',
  english: 'English',
  chinese: '中文',
  deposit_address: '托管地址',
  amount: '数量',
  imbtc_db_page_title: 'imBTC 透明，才更值得信任',
  imbtc_db_page_desc: '你可以通过查询 imBTC 代币合约地址和 BTC 托管地址，了解每一个 imBTC 代币铸造、销毁、转账交易流程，保障 imBTC 的背后拥有1:1 BTC。',
  scan_with_imtoken: 'imToken 扫码访问',
  deposit_tip: '因为赎回 BTC 将会获得手续费，同时消耗矿工费，所以有时已托管数量与 imBTC 已发行数量之间会有微小的偏差。',
  otc_buy: '购买',
  otc_tip_1: '购买将通过第三方平台提供服务，费用已包含在金额中',
  otc_tip_2: '少于 $150 不需要验证 KYC',
  otc_next: '下一步',
  otc_min_buy: '最低购买金额 50 USD',
  otc_max_buy: '最高购买金额 2000 USD',
  unsupported_region: '暂不支持当前地区',
  supported_region: '查询支持地区',
  submit_request: '提交你的需求',
  otc_min_buy_rmb: '最低购买金额 $s RMB',
  otc_max_buy_rmb: '最高购买金额 $s RMB',
  otc_min_buy_asset: '最低购买金额 $s $s',
  otc_max_buy_asset: '最高购买金额 $s $s',
  pls_check_network: '暂无法获取汇率，请检查网络',
  cant_solve_this_trade: '节点不稳定或资金池不足，可能关闭交易',
  otc_exchange: '买卖',
  trading_disable: '交易暂停，请稍后重试',
  PRICE_FAILED: '报价验证失败，请稍后重试(2002)',
  BALANCE_INSUFFICIENT: '余额不足',
  USER_BALANCE_INSUFFICIENT: '余额不足',
  TIMEOUT: '订单超时，请稍后重试(3001)',
  already_connecting: '网络连接中，请稍后重试',
  tried_too_much_times_to_connect: '重连失败，请稍后重试',
  wallet_is_connected: 'Wallet connect 已连接',
  imkey_connected: 'imKey 已连接',
  disconnect: '断开连接',
  approving_pls_sign: '请确认签名',
  unApproving: '请确认签名',
  rejected: '签名失败',
  timeout_pls_relaunch: '交易等待超时',
  approve_success: '签名成功',
  unApprove_success: '签名成功',
  unApprove_rejected: '签名失败',
  token_approval_box_title: '授权代币',
  token_approval: '授权 {{symbol}} 代币',
  view_more_support_wallet: '查看更多支持钱包',
  use_imtoken_scan: '使用 imToken 钱包扫码连接',
  back: '返回',
  close: '关闭',
  approve_tokenlon_sell_$token: 'Tokenlon 需要你的许可来使用你的 $s ',
  approve_token_to_tokenlon: '请在你的钱包进行验证。',
  unApprove_tokenlon_sell_$token: '取消 Tokenlon 使用你的 $s 的许可',
  unApprove_token_to_tokenlon: '取消 Tokenlon 使用你的 {{symbol}} 的许可',
  wrong_network: '错误的网络',
  wrong_network_tips: '错误的网络环境，请切换至以太坊主网环境进行连接',
  wrong_network_dev_tips: '错误的网络环境，请切换至SEPOLIA测试网环境进行连接',
  sign_metamask_tips: '请求中，请在 MetaMask 中确认签名',
  sign_walletconnect_tips: '请求中，请在设备中确认签名',
  waiting_for_confirm: '等待交易确认',
  trade_rejected: '交易被拒绝，请重新下单',
  deposit_wbtc: 'WBTC 网关已托管',
  deposit_tip_wbtc: 'WBTC 托管数量 = WBTC 余额 + imBTC 待调仓余额',
  custody_tip_wbtc: '注：Tokenlon 为方便用户可以闪兑 WBTC 建立了兑换池，资金来自于冷钱包托管的 BTC.',
  // imKey Connect
  connect_imkey_wallet: '连接 imKey Pro 硬件钱包',
  connect_imkey_wallet_usb: '使用 USB连接 imKey Pro 硬件钱包',
  pls_install_first: '请先安装',
  install_imkey_pro_on_tokenlon: '在 Tokenlon Web 中点击连接按钮',
  connect_imkey_btn: '连接',
  connecting_imkey_btn: '连接中...',
  widthout_imkey: '没有硬件钱包？订购 ',
  pls_store_personal_funds: '请将个人资金进行冷存储',
  problem_with_connection: '连接出现问题？',
  get_help: '获取帮助',
  first: '',
  sign_imkey_connect_tips: '请求中，请在 imKey Pro 中签名确认',
  // LON Dashboard & Referral
  holders: '持有者',
  tokenlon_24h_vol: 'Tokenlon 24H 交易量 (USD)',
  my_data: '我的数据',
  lon_balance: 'LON 余额',
  tokenlon_fee: '手续费率',
  waiting_for_airdrop: '等待领取',
  reward_record: '奖励记录',
  share_lon_title: '邀请好友，共享 +20% 返佣奖励',
  activate: '接受邀请',
  share: '邀请好友',
  lon_invitation_code: 'LON 邀请码',
  tft_invitation_code: 'TFT 用户专属邀请码',
  my_wallet_address: '我的钱包地址',
  activate_success: '绑定成功',
  activate_success_subtitle: '参与交易挖矿，与你好友共享 +20% 返佣奖励',
  forward_to_exchange: '前往交易',
  participate_in_trade: '参与交易',
  share_friends: '邀请 3 位好友并完成一笔交易，开启激励奖励',
  tfter_share_friends: '邀请 1 好友并完成一笔交易，开启激励奖励',
  share_and_gain_friends_reward: '你与好友将会分别获得 15% 和 5% 返佣奖励 ',
  share_image: '分享图片',
  share_link: '分享链接',
  invitation_record: '邀请记录',
  weekly_reward: '奖励结算',
  reward_amount: '奖励',
  reward_quantity: '奖励数量',
  number_of_reward: '奖励数量',
  invitation_DISABLED: '已禁用',
  invitation_ENABLED: '已绑定',
  invitation_PENDING: '等待交易',
  lon_share_title: '我的邀请码',
  lon_activate_title: '邀请码绑定',
  error_activate: '绑定失败',
  success_activate: '绑定成功',
  liquidity: '挖矿产出',
  trades: '交易笔数',
  check_after_connect_wallet: '连接钱包后查看',
  no_invitation_code_yet: '暂无邀请码',
  status: '状态',
  friends_count: '邀请好友',
  activated_friends: '激活好友',
  reward_count: '累积奖励',
  activated: '已激活',
  activating: '未激活',
  waiting_drop: '等待领取',
  waiting_calc: '等待结算',
  total_supply: '总量',
  volume_24h: '24H 交易量',
  round_update_time: '每周六 08:00 AM(UTC+8) 更新',
  round2_update_time: '2020.12.20 08:00 AM(UTC+8) 更新',
  weekly_power: '本周算力',
  round_power: '本轮算力',
  daily_power: '当前算力',
  weekly_power_rate: '算力份额',
  claim_time: '预计时间',
  reward_thead_power: '累积算力/算力份额',
  url_cpoied: '已复制分享链接',
  invalid_invitation_code: '邀请码错误，请检查邀请码有效性',
  invitation_code: '邀请码',
  not_allow_activate_code_address: '绑定错误，该邀请码不允许绑定当前钱包地址',
  invitation_tip: '等待 3 位好友绑定邀请码并交易后，开启邀请奖励',
  invitation_tip_tft: '等待 1 位好友绑定邀请码并交易后，开启邀请奖励',
  round_reward_status: '本轮算力/状态',
  // landing page
  lon_landing_page_title: 'LON',
  lon_lading_page_description: 'Tokenlon 是基于区块链实现的去中心化交易支付结算协议',
  connect_user_and_participants: '连接用户与生态参与者',
  goto_trading_mining: '参与交易挖矿',
  visited_mining_data: '进入概览',
  why_choise_lon: 'Tokenlon 优势',
  flow_inlet: '流量入口',
  millions_user_support: '获得 imToken 千万用户支持',
  trading_volume: '40 亿美金交易量',
  top3_dex: 'TOP3 以太坊去中心化交易所',
  high_success_rate: '99.8% 链上结算成功率',
  leading_dex_protocol: '领先主流 DEX 协议',
  what_can_lon_do: 'LON 的用途',
  incentive: '激励',
  participants_can_get_lon: '参与 Tokenlon 交易与生态建设，将会获得 LON 奖励',
  trade_discount: '交易折扣',
  fee_discount: 'LON 持有者将会获得交易手续费折扣',
  governance: '治理',
  governance_make_tokenlon_better: 'LON 持有者参与 Tokenlon 治理，让 Tokenlon 变得更好',
  lon_fqa: 'LON 常见问题',
  learn_trading_mining: '了解 LON 代币和交易挖矿教程。',
  lon_power_data: '算力数据',
  timeframe_weekly: '7日',
  timeframe_monthly: '30日',
  my_tokenlon_data: '我的交易',
  my_lon_data: '我的算力',
  trade_power: '交易算力',
  invitation_power: '邀请算力',
  invitation_power_trade: '邀请算力',
  current_invitation_power: '当前邀请算力',
  power_top10: '算力排行榜',
  trade_top10: '交易排行榜',
  timeframe_this_week: '本周',
  timeframe_all: '全部',
  tokenlon_trade: 'Tokenlon 交易',
  tokenlon_power: 'Tokenlon 算力',
  power: '算力',
  lon_count: 'Lon 总量',
  incentive_plan: '激励计划',
  buy_back: '回购计划',
  buy_back_description: '协议所收取的交易费用将被用于回购 LON，回购的 LON 将发送到财库及用于质押奖励',
  distribute_token: '代币分配',
  community_reward: '社区激励',
  core_team: '核心团队',
  stakeholders: '利益相关方',
  development_fund: '发展储备金',
  system_design: '系统设计',
  system_design_description: '针对目前 DEX 市场流动性割裂的问题，Tokenlon 采用链上链下配合的方式路由和聚合不同流动性源，包括链下专业做市商和链上算法自动做市商（比如：Uniswap、Curve 等），自动为用户选择最佳交易路径。',
  economic_mechanism: '经济机制',
  economic_buy_back: '回购',
  economic_staking: '质押',
  economic_mining: '挖矿',
  economic_treasury: '财库',
  economic_governance: '治理',
  economic_buy_back_description: '协议所收取的手续费用于回购 LON，回购的 LON 将被转移至财库以及用于质押奖励。',
  economic_staking_description: 'LON 持有者将能够通过参与质押获得手续费折扣、治理权利以及质押奖励。',
  economic_mining_description: '在 LON 发行数量达到上限前，每次回购都将触发铸币，铸出的 LON 用于激励网络参与者。',
  economic_treasury_description: '财库是由社区通过治理支配的 LON 储备池，用于建设和促进生态发展。',
  economic_governance_description: 'Tokenlon 治理分阶段开放，逐步将决策权交予社区。目前可通过 Snapshot 参加早期治理。',
  road_map: '里程碑',
  view_system_design_drawing: '查看系统设计图',
  genesis_mining: '创世挖矿',
  economic_build_model: '经济模型搭建',
  launch_governance: '启动治理',
  public_network: '开放网络',
  cross_chain_channel: '跨链通道',
  LON_litepaper: 'LON Litepaper',
  litepaper: '白皮书',
  LON_litepaper_description: '查看 Litepaper 了解更多详情。',
  // etherscan ad
  etherscan_ad_q1: 'Tokenlon 是什么？',
  etherscan_ad_q2: '什么是 LON？',
  etherscan_ad_q3: '如何获取 LON？',
  etherscan_ad_a1: 'Tokenlon 是由 imToken 投资孵化的去中心化平台，提供便捷安全的币币兑换服务。自去年上线以来，Tokenlon 已帮助超过 11 万名用户完成累计 26 亿美元交易。',
  etherscan_ad_a2: 'LON 是 Tokenlon 发布的应用型平台代币，12月底开启流通交易，持有 LON 可享受手续费减免，未来还可以参与 Staking 和社区治理投票。',
  etherscan_ad_a3: '连接钱包后，在 Tokenlon 交易即可获得 LON，目前挖矿已经进入第二阶段，LON 预计在 2020 年底流通并开启兑换。',
  etherscan_ad_warning: '风险提示：LON 没有任何资金募集，且代币合约尚未部署，请谨防骗局',
  etherscan_ad_title: 'Tokenlon 交易挖 LON',
  etherscan_ad_subtitle: '火热进行中',
  join_farm: '参与挖矿',
  farm_rules: '挖矿细则',
  etherscan_ad_trade_tip: '交易免费得 LON',
  etherscan_ad_lon_tip: '如何免费获得LON',
  etherscan_lon_trade: '12月底开启流通交易',
  // stage1
  trade_mining: '交易挖矿',
  trade_mining_records: '交易挖矿记录',
  lp_mining: '流动性挖矿',
  lp_continue_mining: '什么是持续挖矿？',
  lp_continue_mining_detail: '从第四期起，流动性挖矿将从单期挖矿模式转为持续挖矿模式。在持续挖矿模式下，质押者无需从上一期赎回并质押到下一期，LP token 将会自动转入下一期参与挖矿，持续赚取未来每一期奖励。',
  lp_continue_mode: '流动性挖矿开启持续挖矿模式',
  lp_continue_more: '了解详情',
  stake_mining: '质押挖矿',
  lp_reward: '奖励($s / 周)',
  lp_staked: '$s 全网已质押',
  lon_calc_reward: 'LON 本周累计奖励',
  weekly_calc_power: '全网算力（本周）',
  reward_waiting_for_claim: '待领取奖励',
  claim: '领取',
  claim_reward: '领取奖励',
  balance: '余额',
  add_liquidity: '添加流动性',
  redeem: '赎回',
  redeem_lp: '退出质押',
  redeem_all: '全部',
  lp_4_tips: '从第四期起，每期流动性挖矿结束后，无需赎回操作，LP token 将会自动转入新一期参与挖矿。领取奖励和赎回也将在新的一期中进行。',
  force_redeem_penalty: '扣除罚金',
  staked: '已质押',
  stake: '质押',
  stake_input_label: '质押数额',
  stake_rule: '质押挖矿规则',
  lp_rule: '流动性挖矿规则',
  genesis_reward: '创始挖矿奖励',
  exit: '一键退出',
  exit_confirm: '确认退出',
  exited: '赎回并领取成功',
  redeem_and_claim: '全部赎回并领取奖励',
  redeem_and_claim_long: '一键取出全部 LP token 并领取 LON 奖励',
  approve: '授权',
  approved: '已授权',
  staking: '质押中',
  stake_done: '质押成功',
  not_redeemable: '没有抵押，无法赎回',
  not_claimable: '没有可领取的奖励',
  not_stakable: '没有可供抵押的代币',
  apy: '预计年化收益',
  claiming: '领取中',
  claimed: '领取成功',
  redeeming: '赎回中',
  redeemed: '赎回成功',
  exiting: '退出流动性挖矿中',
  over: '结束',
  page_not_found: '页面不存在',
  predict_reward: '预计奖励',
  claimable_reward: '奖励',
  lon_to_be_claimed: 'LON 待领取数量',
  interim_subtitle: '创世挖矿已结算，数据核算中',
  configurable_error: '请升级 iOS 系统或使用 Metamask 访问',
  defi_warning: '参与流动性挖矿，有可能存在无常损失，请自行评估风险。',
  // brand
  brand: '品牌资源',
  brand_banner_title: 'Tokenlon 是基于区块链网络实现的去中心化交易支付结算协议',
  download_all: '全部下载',
  tokenlon_asset_subtitle: 'Tokenlon 将作为应用端交易和支付的基础设施，与生态合作伙伴一起创造健壮且丰富的全球金融市场。',
  lon_asset_subtitle: 'LON 是 Tokenlon 发行的应用型代币，用于激励生态中各方并确保其能够协力推动生态发展。LON 代币有两个主要用途：费率折扣和治理。',
  imbtc_asset_subtitle: 'imBTC 具有智能合约的可互操作性，无缝融入去中心化交易和金融服务中，同时为以太坊生态注入强大的资产流动性。',
  download: '下载',
  system_is_maintaining: '系统正在维护和升级中',
  // stage1-2
  closed_note_period_1: '第',
  closed_note_period_2: '期流动性激励已结束，请退出 LP token 质押并认领你的奖励。领奖后你还可以参与 LON 质押，获取最高 49.1% 的稳定年化收益，没有无常损失风险。',
  final_lp_note: 'LON 流动性激励已结束，请退出 LP token 质押、认领奖励并赎回你的资产。赎回后你还可以参与 LON 质押，获取最高 49.1% 的稳定年化收益，没有无常损失风险。',
  final_lp_note_detail_1: 'LON 流动性激励已结束，请退出 LP Token 质押并领取你的奖励，并通过 ',
  final_lp_note_detail_2: ' 赎回你的 ',
  final_lp_note_detail_3: ' 和 ',
  final_lp_note_detail_4: '。赎回后你还可以参与 LON 质押，获取最高 49.1% 的稳定年化收益，没有无常损失风险。',
  final_step_1: '第 ',
  final_step_2: ' 步',
  final_lp_btn_1: '了解 LON 质押',
  final_lp_btn_2: '继续赎回',
  final_redeem: '赎回代币',
  final_lp_redeem_note: '小贴士：赎回代币后你还可以参与 LON 质押，获取最高 49.1% 的稳定年化收益，没有无常损失风险。',
  forward_pool: '参与挖矿',
  closed_pools: '往期流动性挖矿',
  closed_banner_title: '往期流动性挖矿',
  closed_banner_subtitle: '请领取你的 LP token 和 LON 奖励',
  circulation: '流通量',
  // v5
  current_slippage_$value_tips: '当前滑点 $s%，此处为最低收到数量',
  setting_slippage_toast: '滑点已实时更新',
  slippage_$range_changed_warning_message: '滑点可设置范围已变更为 $s%',
  packing_failed: '打包失败',
  exchange_pending: '等待中',
  tokenlon_exchange_timeout: '交易超时',
  tokenlon_latest_deal: '最新兑换',
  deal_time: '成交时间',
  help_and_feedback: '帮助与反馈',
  pay_amount: '支付金额',
  trade_fee: '交易费',
  computing_power_reward: '交易算力',
  computing_invite_power_reward: '邀请算力',
  receive_address: '收款地址',
  pay_address: '付款地址',
  find_detail_info: '查询详细信息',
  copy_success: '拷贝成功',
  handlingFeeRate: '手续费费率',
  order_expired: '报价已过期，请重新下单',
  stand_fee: '标准交易费',
  discount: '优惠',
  charge_detail: '手续费明细',
  tokenlon_fee_modal_notation_of_amm: '注：表单数量已扣除手续费，即你最终收到的数量',
  // spotswap setting
  settings: '设置',
  set_gas_fee_label: '代币支付矿工费',
  set_slippage: '设置滑点',
  slippage: '滑点',
  slippage_placeholder: '自定义 ({{min}}%-{{max}}%)',
  slippage_normal_tips: '说明：滑点越小，交易被抢跑的几率越小',
  slippage_invalid_tips: '请输入有效数值，自定义滑点设置支持 {{min}}%～{{max}}%',
  order_preview: '订单预览',
  tx_id: '交易号',
  net_contribute: '净贡献',
  // stage3
  leaderboard_nav: '数据',
  updates: '动态',
  community: '社区',
  bounty: '奖金任务',
  forum: '治理论坛',
  vote: '投票',
  twitter: 'Twitter',
  discord: 'Discord',
  tokenlon_sdk: 'Tokenlon SDK',
  mm_onboarding: '做市商文档',
  open_api: '开放 API',
  support_token: '支持币种',
  brand_and_logo: '品牌',
  market_maker_document: '做市商文档',
  smart_contract: 'Tokenlon 智能合约',
  sdk: 'Tokenlon SDK',
  mmsk_tool: '服务中转工具',
  mmproxy_tool: '代币储备库',
  // trade mining stage 2
  my_invitation: '我的邀请',
  my_invitation_tip_1: '小贴士：',
  my_invitation_tip_2: '成功推荐你的好友绑定你的邀请码来 Tokenlon 交易，你可以获得更多算力，赢取更多 LON 奖励。开始邀请吧！',
  current_total_trade: '总交易额（本期 ）',
  current_power: '当前算力',
  current_power_share: '当前算力份额',
  current_power_share_info: '当前交易算力份额 = 你的当前算力÷全网当前算力。当你获得算力份额时，即可预估获得 LON 的奖励数量。预估 LON 奖励数量 = 当前算力份额 * 本期当前累计奖励 LON 数量。注：全网算力将会随着参与者增加而增加，因此，当前预估数量的计算仅作为参考，奖励将以最终结算为准。',
  current_power_myshare_info: '当前邀请算力份额 = 你的当前算力÷全网当前算力。当你获得算力份额时，即可预估获得 LON 的奖励数量。预估 LON 奖励数量 = 当前算力份额 * 本期当前累计奖励 LON 数量。注：全网算力将会随着参与者增加而增加，因此，当前预估数量的计算仅作为参考，奖励将以最终结算为准。',
  current_invitation_activated: '激活数量 (本期)',
  mining_data: '挖矿数据',
  go_trade: '开始挖矿',
  invitation_gift_tip: '每邀请一位好友并交易激活可获得额外 10 算力的奖励',
  activate_btn: '绑定',
  invite_btn: '邀请',
  current_trade_mining_countdown_title: '第 {{phase}} 期交易挖矿进行中，结束倒计时',
  current_trade_mining_countdown_tip: '倒计时结束本轮交易挖矿结束，同时新一轮交易挖矿开启',
  liquidity_provider: '流动性提供方',
  trader: '交易者',
  relayer: '中继方',
  recommender: '推荐者',
  phase0_mining: '创世挖矿奖励',
  total_mining_power: '总交易算力',
  total_invitation_power: '总邀请算力',
  trade_mining_desc: '在 Tokenlon 完成交易即可获得 LON 奖励，奖励来自于 LON 回购时触发的铸币，奖池将在每轮交易挖矿结束后公布。',
  trade_mining_report: '第 {{phase}} 期交易挖矿数据',
  trade_mining_total_volume: '总交易量',
  trade_mining_total_rewards: '总奖励',
  trade_mining_participants: '参与地址数',
  calculating: '核算中……',
  more_details: '查看更多',
  to_view_data: '后参与质押/查看质押收益',
  daily_output: '每日产出',
  no_records: '暂无记录',
  round_no: '期号',
  distributed_time: '结束日期',
  status_claimed: '已领取',
  status_expired: '已过期',
  expired_explain: '经 Tokenlon 社区提案 <1>TIP39</1> 决定，逾期未认领的创世挖矿奖励已转入社区财库。',
  round_no_1: '第 ',
  round_no_2: ' 期',
  trade_reward: '交易奖励',
  trade_amount: '交易金额',
  total_power: '全网算力',
  current_total_power: '当前全网算力',
  total_referral_power: '全网邀请算力',
  current_trade_power: '当前交易算力',
  referral_reward: '邀请奖励',
  activated_address: '激活地址',
  total_invited_friends: '累计邀请好友',
  total_activated_friends: '累计激活好友',
  bond_status: '绑定状态',
  trade_and_referral_power: '激活和交易算力奖励 (本期)',
  bond_time: '绑定时间',
  participant: '网络参与者奖励比例',
  power_in_count: '当前全网算力',
  load_more: '加载更多',
  waiting_for_count: '等待结算',
  // lon sub nav
  sub_nav_overview: '概览',
  sub_nav_dashboard: '数据看板',
  sub_nav_trade_mining: '交易挖矿',
  sub_nav_liquity_mining: '流动性挖矿（已结束）',
  sub_nav_staking: '质押',
  sub_nav_buyback: '回购',
  waiting_for_claiming: '等待领取',
  unbond: '未绑定',
  // staking
  current_stake_reward: '收益(含质押 LON)',
  current_stake_reward_tip: '质押奖励将会定期发放。该数值包含已质押 LON 数量，xLON 余额变动时，该数值也会发生变动。',
  current_lon_reward: '可赎回 LON 数量',
  apy_avg: '年化收益',
  apy_avg_tips: '自 LON 质押 2021 年 4 月 1 日上线以来的历史累计收益率，仅供参考。',
  punish_tips_1: '处罚金额 = 处罚金额比例 x 当日可领取的 LON 数量。',
  punish_tips_2: '处罚金额比例最大为 5%（本息），并且随解锁期时间线性递减。',
  force_redeem_notice_title: 'LON 强制赎回须知',
  keep_waiting: '继续等待',
  confirm_redeem: '确认赎回',
  trigger_cooldown_period: '触发解锁期',
  unlock: '解锁',
  unlocking: '解锁中',
  redeem_lon_notice: 'LON 赎回须知',
  redeem_lon_notice_tips_1: '确认赎回 LON 质押前，需确认解锁质押，触发质押解锁期，解锁期为 168 个小时。',
  redeem_lon_notice_tips_2: '在解锁期结束后退出质押，您可以直接提取累计收益，无须支付罚金。',
  redeem_lon_notice_tips_3: '在解锁期结束前退出质押，您可以提取的累计收益，须扣除提前退出质押的罚金。',
  force_redeem: '强制赎回',
  redeem_immediately: '立即赎回',
  unlock_countdown_title: '解锁期倒计时',
  unlock_countdown_subTitle: '距离您的质押解锁期结束还有',
  unlock_countdown_tips1: '您可以选择继续等待，在解锁期结束后退出质押，直接提取累计收益，无须扣除罚金。',
  unlock_countdown_tips2: '在解锁期结束后退出质押，您可以直接提取累计收益，无须支付罚金。',
  unlock_countdown_tips3: '在解锁期结束前退出质押，您可以提取的累计收益，须扣除提前退出质押的罚金。',
  redeem_penalty_title: '您须支付的罚金为',
  extract_amount: '提取金额',
  input_xlon_amout_placeholder: '输入xLON数量',
  redeem_lon_deposit: '赎回 LON 质押',
  estimate_rate: '预估汇率',
  current_rate: '比率 (当前)',
  estimate_redeem: '预计赎回',
  extractable: '可提取',
  unextract_tips: '这次未提取完的余额再次提取，将进入下一个解锁期',
  // buyback
  buyback_notification: 'LON 回购机制调整公告',
  buyback_page_title: 'LON 回购',
  buyback_page_desc: 'Tokenlon 协议所收取的手续费将会被用于在市场上回购 LON，回购的 LON 将被转移至财库以及用于质押奖励，同时将会按比例铸出 LON 用作交易挖矿奖励。',
  goto: '前往',
  buyback_subtitle_balance: 'Tokenlon 收入',
  buyback_tips_history_fee: 'Tokenlon 协议捕获的累计的手续费收入，金额按照当时捕获代币时的美元价值来计算。',
  buyback_card_history_fee: '累计手续费',
  buyback_card_current_balance: '手续费余额',
  buyback_tips_current_balance: 'Tokenlon 协议当前手续费余额。因捕获的代币价值会随着市场波动而变化，手续费余额也会随之变化。',
  buyback_card_treasury: '社区财库',
  buyback_tips_treasury: '<0>Tokenlon 社区财库余额</0>，因财库的代币价值会随着市场波动而变化，因此社区财库金额也会随之变化。',
  buyback_subtitle_buyback: '回购详情',
  buyback_card_buyback_amount: '累计回购',
  buyback_tips_buyback_amount: '累计回购 LON 的数量，每次回购完成该数值都会增加。',
  buyback_card_scaleindex: '铸币系数',
  buyback_tips_scaleindex: '每回购 1 个 LON，将铸造出 1 个 LON 用于奖励 LON 交易挖矿参与者。',
  buyback_chart_price_title: 'LON 价格',
  staking_chart_lon_circulation_title: 'LON 流通量',
  price: '价格',
  chart_date_unit_week: '1周',
  chart_date_unit_month: '1月',
  chart_date_unit_all: '全部',
  buyback_chart_assign_title: '铸币与分配',
  buyback_chart_assign_title_tips_1: '总回购数量：总回购 LON 数量分配到财库和质押挖矿奖励池的数量',
  buyback_chart_assign_title_tips_2: '· LON 财库：当回购成功后，30% 的 LON 将被分配到财库',
  buyback_chart_assign_title_tips_3: '· 质押挖矿奖励池：当回购成功后，70% LON 将被分配 到 LON 质押挖矿奖励池',
  buyback_chart_assign_title_tips_4: '铸币数量：当成功回购 1 LON，将会铸造 1 LON',
  buyback_chart_assign_title_tips_5: '注：部分代币的分配比例会有所不同，例如：imBTC / WETH。',
  buyback_history_list_title: '回购记录',
  buyback_card_remain_title: '合约手续费余额列表',
  buyback_tips_remain_title_1: '该列表记录了 Tokenlon 合约中捕获的手续费余额，包含',
  buyback_tips_remain_title_2: '根据每个币种的价值和特性，根据治理，系统会为每个代币设置不同的回购比例（例如 WETH 等）。当发起回购后，系统将会把币种价值拆分为预先设置好的比例，向公开市场回购 LON 分配到质押奖励池和财库。注：分配到财库的比例将会根据币种用途实现两种不同的转入方式：1. 回购 LON 并转入财库；2. 不回购 LON 直接转入财库。',
  buyback_card_remain_subtitle: '任何人都可以发起手续费回购 LON',
  buyback_card_remain_action: '查看回购记录',
  buyback_history_list_title_date: '日期',
  buyback_history_list_title_buyback_fee: '回购费用',
  buyback_history_list_title_buyback_fee_tip: '下周可用于回购 LON 的代币总价值',
  buyback_history_list_title_buyback_amount: '回购数量',
  buyback_history_list_title_mint_amount: '铸币数量',
  buyback_history_list_title_status: '状态',
  buyback_remain_table_col_progress: '代币名称/最小可回购进度',
  buyback_min_buyback: '最小可回购',
  buyback_remain_table_col_progress_tips: '最小可回购进度条展示了所需达到的最小回购数量。当进度条充满时，则满足回购标准，可发起回购。当进度条未充满时，则未满足回购标准，不可发起回购。',
  buyback_remain_table_col_value: '数量/价值',
  buyback_remain_table_col_remain: '本次可回购/可回购数量',
  buyback_remain_table_col_remain_tip_1: '可回购数量：可回购的 LON 总数量；',
  buyback_remain_table_col_remain_tip_1_1: '可回购数量 = 代币美元价值 / 当前 LON 价格',
  buyback_remain_table_col_remain_tip_2: '本次可回购数量：每次可发起回购的数量；',
  buyback_remain_table_col_remain_tip_3: '为避免每次回购数量过大对市场造成影响，或数量过小造成矿工费浪费。因此，合约对可回购数量设定了最小值和最大值，即满足限额区间时可发起回购。如未满足限额时，不可回购。',
  buyback_remain_table_col_remain_tip_4: '本次可回购数量 = 代币限额区间 / 当前 LON 价格',
  buyback_remain_table_col_remain_tip_5: '注：当回购发起过于频繁时，系统将会限制发起回购的频率，因此，当限制发起回购时，你需要等待限制解除后再尝试发起回购。',
  buyback_remain_table_op_buyback: '发起回购',
  buyback_remain_table_op_buyback_tips: '表示当前可发起回购',
  buyback_remain_table_op_closed: '暂未开启',
  buyback_remain_table_op_closed_tips: '表示该代币暂未开启回购',
  buyback_remain_table_op_wait: '累计中',
  buyback_remain_table_op_wait_tips: '表示当前手续费正在累计中，暂无可回购的金额',
  buyback_remain_table_op_countdown: '倒计时',
  buyback_remain_table_op_countdown_tips: '表示距离下一次可以发起回购的剩余时间',
  buyback_remain_table_end: '~ 到底了 ~',
  buyback_form_label_set_quantity: '我要回购的金额',
  buyback_form_title: '发起回购',
  buyback_form_subtitle: '发起回购后，将使用合约中的部分金额向交易所购买 LON，成功回购后，合约会自动将购回的 LON 进行分配。只需支付少量矿工费，任何人可发起回购。',
  buyback_form_current_quantity: '本次可用于回购的金额',
  buyback_form_est_quantity: '预估可回购数量',
  buyback_form_submit: '立即发起回购',
  // staking
  staking_title: 'LON 质押',
  staking_subtitle: '参与 LON 单币质押可获得稳定 LON 奖励，xLON 作为质押凭证。',
  staking_buy_lon: '购买 LON',
  staking_market: 'LON/xLON 市场行情',
  staking_total_lon_staked: 'LON 质押量',
  staking_total_xlon_amount: 'xLON 流通量',
  // staking_total_xlon_amount_info: '目前已经在市场上流通的xLON总额。xLON是与LON类似的Token，当您将LON质押到Tokenlon时，您可以获得以xLON计价的质押奖励。每个xLON的价值始终高于一个LON的价值。',
  staking_scale_tips: 'xLON 与 LON 兑换比率将会随着 Tokenlon 协议质押奖励持续分配而上升。',
  staking_assets_xlon_title: 'xLON 余额',
  staking_assets_xlon_title_tips: '目前您账户中持有的xLON数量。其中包含您质押后获得的xLON质押奖励，以及质押的LON本金（以xLON计价）。',
  staking_assets_lon_title: 'LON 余额',
  staking_assets_all: '个人总资产',
  staking_lon_stake: 'LON 质押',
  staking_lon_stake_tips_1: '任何人都可以参与 LON 质押，成功参与质押后你将收到 xLON，xLON 可自由流通且价值与 LON 锚定，xLON : LON 大于等于 1，比率实时变动。',
  staking_lon_stake_tips_2: '质押后你可以随时赎回 LON 并领取收益，赎回需要支付矿工费并等待 7 天冷静期。如需强制赎回，最高需支付 5% （本息）xLON 作为惩罚金。',
  staking_lon_stake_tips_3: '注：1. 罚金比例随解锁期线性递减；2. 罚金将保留在质押奖池中由其他质押者分享，具体详情可以参考',
  staking_lon_stake_faq: ' LON 质押常见问题',
  staking_input_label_stake: '请输入质押数量',
  staking_input_placeholder_stake: '输入LON数量',
  staking_amount_all: '全部',
  staking_currency: '汇率 (当前）',
  staking_gain: '预计获得',
  staking_confirm: '确认质押',
  staking_unstake_tips: '继续等待解锁期结束可无罚金提取全额xLON',
  transaction_wait_tips: '大约需要',
  transaction_view: '查看',
  detail: '查看',
  staking_records_title: '质押/赎回记录',
  staking_records_title_tips: 'xLON : LON 的币值汇率是实时变动的，具体价值请参考以下表格',
  staking_records_col_time: '质押/赎回时间',
  staking_records_col_unlocktime: '解锁时间',
  staking_records_col_type: '类型',
  staking_records_col_amount: '数量',
  staking_records_col_receive: '获取',
  buy: '买入',
  sell: '卖出',
  withdraw_amount_error_tips: '提取金额大于可用余额',
  status_buyback_ing: '回购中',
  status_buyback_success: '回购成功',
  status_buyback_error: '回购失败',
  status_stake_ing: '质押中',
  status_stake_success: '质押成功',
  status_stake_error: '质押失败',
  status_stake_exiting: '退出质押中',
  status_stake_exit_success: '退出质押成功',
  status_stake_exit_error: '退出质押失败',
  status_unlock_ing: '解锁中',
  status_unlock_success: '解锁成功',
  status_unlock_error: '解锁失败',
  fold: '收起',
  unfold: '展开',
  comming_soon: '即将上线',
  lon_holders_enjoy_discount_tips: '持有 LON 或 xLON 均可享受费率优惠',
  trade_success: '交易成功',
  check_on_chain_status: '查看链上情况',
  claim_success: '领取成功',
  claim_failed: '领取失败',
  to_claim: '待领取',
  rules_label: '我确认我已阅读、理解并同意',
  user_rules: '用户使用协议',
  price_lookup: '寻找最优报价中',
  allowance_pending: '等待授权',
  need_allowance: '授权 {{symbol}} 代币以便进行交易',
  select_agreement: '请先勾选用户协议',
  spotswap_quote_validity: '报价有效期 <special>{{countdown}}s</special>',
  spotswap_quote_expired: '报价已过期',
  spotswap_quote_again_btn: '重新报价',
  spotswap_received_row_self_label: '收到数量(含交易费)',
  spotswap_received_row_relayer_label: '收到数量(含结算费)',
  min_received: '最小收到数量',
  get_best_price: 'Tokenlon 已为您找到最佳价格!',
  lookup_failed: '获取汇率失败',
  select_token: '选择代币',
  in_calc: '结算中',
  settled_not_issued: '已结算，未发放',
  all: '全部',
  recommend: '推荐',
  common_base: '常用',
  token_select_warning: '任何人都可以在链上创建代币，请注意防范假冒代币，以免造成不必要的损失。',
  view_on_Etherscan: '在 Etherscan 上查看',
  view_on_Arbiscan: '在 Arbiscan 上查看',
  i_understand: '我已了解',
  redeem_lon_modal_tips1: '领取 LON 奖励前',
  redeem_lon_modal_tips2: ' 请确认您已参与过 Tokenlon 社区投票',
  geo_paragraphs_1: '为匹配用户属地国家的监管政策，LON DApp 已于新加坡时间 2021 年 10 月 31 日起，停止向中国境内用户提供服务。即，中国境内用户已无法访问 LON DApp 页面。',
  geo_paragraphs_2: '已使用此服务的中国境内用户，如果仍未处理相关资产，也请放心，你的资产安全不受影响。',
  geo_paragraphs_3: '如有任何问题，可发送邮件至 support@tokenlon.im 咨询。',
  geo_link: 'https://tokenlon.gitbook.io/docs/blog/announcement#xiang-guan-shu-hui-cao-zuo-jiao-cheng',
  geo_btn: '查询详细教程',
  lp_tips: '关于 LON 流动性激励调整的公告',
  wallet_connect_failed: '钱包连接失败',
  imkey_in_maintained: 'imKey 连接功能正在升级，推荐使用 imToken 进行交易',
  unsupported_chain_id_error: '钱包当前所处的网络不可用，请切换到 {{network}} 网络后再次连接钱包',
  limit_order: '限价单',
  order_sell: '卖出',
  order_buy: '买入',
  order_receive: '收到',
  expired_time: '有效期',
  cancelled_time: '取消时间',
  expired_time_note: '当超过有效时间后，订单将自动取消，且不会产生手续费。',
  expired_time_setting: '设定有效期',
  minutes: '分钟',
  hour: '小时',
  hours: '小时',
  day: '天',
  days: '天',
  weeks: '周',
  month: '月',
  months: '个月',
  authorize_order: '签名授权',
  sign_order_pending: '请在你的钱包中签名限价单...',
  sign_best_buy_pending: '请在你的钱包中签名 Best Buy...',
  sign_order_success: '订单已提交，请在订单列表查看状态',
  sign_order_failure: '签名被拒绝，请重新提交',
  cancel_order_pending: '请在你的钱包中确认...',
  cancel_order_success: '订单已取消',
  cancel_order_failure: '取消失败，请返回确认订单是否已成交',
  open_orders: '活跃订单',
  order_history: '历史订单',
  approve_to_permit2: '授权 {{symbol}} 代币',
  preview_order: '预览订单',
  order_processed: '已成交',
  order_insufficient_balance: '当余额数量充足时该订单才能正常成交',
  order_insufficient_allowance: '您的订单授权不足，请您先取消订单，再重新下单',
  order_in_deprecated_contract: '因合约升级，订单失效，请重新下单',
  no_orders: '暂无订单',
  search_token_prompt: '没找到想要的代币？通过搜索发现更多!',
  refresh_market_price: '设置为市场价',
  cancel_order: '取消挂单无需手续费',
  select_wallet: '选择钱包',
  select_network: '选择网络',
  limit_order_status_pending: '等待成交',
  limit_order_status_partialfilled: '部分成交',
  limit_order_status_fulfilled: '已完成',
  limit_order_status_cancelled: '已取消',
  limit_order_status_expired: '已过期',
  bridges_menu: '跨链桥',
  unmatched_network_message: '当前服务不支持 {{chain}}，请点击切换到 {{expectChain}} 网络',
  unmatched_network_instant_message: '请切换至 {{expectChain}} 网络以使用闪兑功能',
  unmatched_network_limit_order_message: '请切换至 {{expectChain}} 网络以使用限价单功能',
  unmatched_network_best_buy_message: '请切换至 {{expectChain}} 网络以使用 best buy 功能',
  unmatched_network_swap_message: '请切换至正确的网络以使用 Tokenlon 服务',
  switch: '切换',
  switch_network: '切换网络',
  switch_network_failed: '切换网络失败',
  updated_at: '更新于',
  provider_error_code_UNKNOWN_ERROR: '遇到技术问题，请稍后重试',
  provider_error_code_NOT_IMPLEMENTED: '遇到技术问题，请稍后重试',
  provider_error_code_UNSUPPORTED_OPERATION: '遇到技术问题，请稍后重试',
  provider_error_code_NETWORK_ERROR: '遇到网络问题，请稍后重试',
  provider_error_code_SERVER_ERROR: '遇到技术问题，请稍后重试',
  provider_error_code_TIMEOUT: '请求超时，请稍后重试',
  provider_error_code_BUFFER_OVERRUN: '出现技术问题，请稍后重试',
  provider_error_code_NUMERIC_FAULT: '遇到技术问题，请稍后重试',
  provider_error_code_MISSING_NEW: '遇到技术问题，请稍后重试',
  provider_error_code_INVALID_ARGUMENT: '遇到技术问题，请稍后重试',
  provider_error_code_MISSING_ARGUMENT: '遇到技术问题，请稍后重试',
  provider_error_code_UNEXPECTED_ARGUMENT: '遇到技术问题，请稍后重试',
  provider_error_code_CALL_EXCEPTION: '链上交易失败，请稍后重试',
  provider_error_code_INSUFFICIENT_FUNDS: '钱包余额不足以支付矿工费，请给账户充值，或等待矿工费降低后重试',
  provider_error_code_NONCE_EXPIRED: '交易请求提交失败，请稍后重试',
  provider_error_code_REPLACEMENT_UNDERPRICED: '交易请求提交失败，请稍后重试',
  provider_error_code_UNPREDICTABLE_GAS_LIMIT: '交易请求提交失败，请稍后重试',
  provider_error_code_TRANSACTION_REPLACED: '交易请求提交失败，请稍后重试',
  activity_limitorder_title: '资产充值',
  activity_limitorder_desc: '将资产<1>充值至 Arbitrum</1> 来体验限价单功能，建议优先充值 ETH，查看充值及使用<3>完整教程</3>。',
  activity_weth_title: '将 ETH 转换为 WETH',
  activity_weth_desc: '限价单功能仅支持 ERC-20 代币，如需交易 ETH，请将 ETH 转换为 WETH 再进行交易。（注：请预留一些 ETH 作为网络矿工费），<1>前往转换</1>。',
  activity_exchange_title: 'Ethereum 主网上线限价单功能',
  activity_exchange_desc: '你可以在 Ethereum 或 Arbitrum 网络进行挂单交易，限时 0 费用。',
  activity_v6_title: '你正在使用新版的 Tokenlon!',
  understand_v6: '了解新功能',
  back_to_v5: '回到旧版',
  spread_up: '高于市场汇率 {{value}}%',
  spread_down: '低于市场汇率 {{value}}%',
  import: '导入',
  risk_reminder: '风险提示',
  token_select_blocked: '不支持该代币进行交易',
  unknown_source: '未知来源',
  sign_limit_order_failed_1001: '{{inputTokenSymbol}}余额不足',
  sign_limit_order_failed_1002: '无效的有效期',
  sign_limit_order_failed_1003: '开启代币授权，以便代币兑换',
  sign_limit_order_failed_1004: '无效的订单签名',
  sign_limit_order_failed_1005: '获取余额失败，请稍后重试',
  sign_limit_order_failed_1006: '不支持该代币进行交易',
  sign_limit_order_failed_1007: '钱包地址存在异常行为，暂时无法交易。如有疑问请联系 bd@tokenlon.im (1007)',
  sign_limit_order_failed_1008: '{{inputTokenSymbol}} 余额已被活跃订单占用，请取消订单后重试',
  sign_limit_order_failed_1009: 'Request Body 解析失败，请求无效',
  sign_limit_order_failed_1010: 'OrderHashes 请求参数缺失',
  sign_limit_order_failed_1011: 'CancelSig 请求参数缺失',
  sign_limit_order_failed_1012: '暂无可取消的订单',
  sign_limit_order_failed_1013: '无法取消他人的订单',
  sign_limit_order_failed_1014: '该订单不存在',
  sign_limit_order_failed_1015: '无法取消订单, 该订单已处于链上处理状态，无法取消，请耐心等待订单完成（通常在半小时内）',
  sign_limit_order_failed_1016: '取消失败，请返回确认订单是否已成交',
  network_label_spot: '闪兑、限价单',
  network_label_limit: '限价单、DCA+',
  spread_warning_check: '我确定将以低于市场 {{spread}} 的价格卖出 {{symbol}}',
  trade: '交易',
  products: '交易产品',
  preview_warning: '订单金额越高，越容易在达到挂单价时成交',
  preview_warning_title: '说明',
  preview_warning_content: '若你的订单迟迟无法成交，可能有以下几个原因',
  preview_warning_option_1: '订单成交可能会对 Taker 造成亏损：在确认执行你的订单之前，Taker 必须考虑完成该笔订单所带来的收益和矿工费支出，若订单对于 Taker 可能会造成亏损，即矿工费支出大于订单收益，订单可能部分成交甚至无法成交，建议提高订单金额来避免这种情况发生。',
  preview_warning_option_2: '代币流动性不足：你所交易的币种流动性无法满足你的订单金额，或者没有流动性。',
  preview_warning_option_3: '代币余额不足：当你挂单后，在订单未成交前将代币转走，造成钱包余额不足以完成交易。',
  got_it: '知道了',
  lo_search_feedback_eth: '暂不支持 ETH，请将 ETH 转换为 WETH 再进行挂单',
  instant_swap: '闪兑',
  gto_dapp: 'BTC 跨链网关服务',
  ad_banner_label: '活动',
  the_merge_announcement: 'Tokenlon 闪兑业务暂停服务的公告',
  exchange_tab_instant: '闪兑',
  exchange_tab_limit: '限价单',
  market_rate: '市价',
  free_of_charge: '限时免费 ($0)',
  adjust_the_selling_price: '调整卖出价格',
  review_order_anyway: '继续预览订单',
  lo_low_price_sell_warning_content: '请注意，你将以低于市场价 {{percent}}% 的价格卖出 {{tokenSymbol}}，将会造成资产损失，建议调整挂单价。',
  lo_low_price_buy_warning_content: '请注意，你将以高于市场价 {{percent}}% 的价格买入 {{tokenSymbol}}，将会造成资产损失，建议调整挂单价。',
  tool: '工具箱',
  wrap_eth: '封装 ETH',
  wrap_eth_desc: '封装 ETH 为 WETH',
  unwrap_eth: '封装 ETH',
  review: '预览',
  weth_statement: '了解更多关于 <1>WETH</1>',
  review_wrap: '预览封装',
  gas_fee: '矿工费',
  trading_fee: '手续费',
  network: '网络',
  confirming: '确认中',
  confirming_notice: '请勿离开此页面，等待交易完成',
  done: '完成',
  network_changed: '当前网络环境已改变，请重新进行操作',
  gas_reserve_warning: '在以太坊主网上每次与合约交互都需要使用 ETH 支付矿工费，建议你在钱包内预留一些 ETH，以免影响后续正常使用',
  wrap_failed_info: '造成失败可能的原因： <1> <2>网络拥堵情况波动较大，矿工费不足</2> <2>WETH 合约调用失败</2> <2>具体原因请查看交易哈希，请尝试重新进行封装</2> </1>',
  try_again: '重试',
  default: '默认',
  // TradeFee intro modal
  trade_fee_intro_title: 'Tokenlon 费率介绍',
  ok: '好的',
  enable: '启用',
  later: '稍后',
  current_trade_fee: '当前交易费率',
  discounted_trade_fee: '已优惠',
  stablecoins_trade_fee_tooltip: '稳定币间费率为 0.04%',
  standard: '无优惠',
  lon_holding_balance: 'LON 持有量',
  lon_holding_balance_tip: '包含已质押 LON 数量',
  last_30d_volume: '近30天交易量',
  in_30d_volume: '30天交易量',
  trade_fee_intro_note: '注：以上条件满足其中之一即可享受费率优惠，稳定币间兑换费率为 0.04%，更多关于 <1>Tokenlon 费率介绍</1>',
  trading_rewards: '交易奖励',
  check_lon_rewards: '查看 LON 奖励',
  instant_order_pending_tips: '完成时间以链上实际情况为准，请留意钱包余额变动',
  // lon reward modal
  lon_reward_title: 'LON 交易奖励计划',
  lon_reward_pool: '本期总奖池',
  countdown: '倒计时',
  lon_reward_note: '你可以在倒计时结束后，查看并领取你的 LON 奖励。了解更多关于 <1>LON 交易奖励</1>。',
  check_reward: '查看奖励',
  info_trade_fee: '交易费率',
  info_trade_fee_intro: 'Tokenlon 费率',
  // pay with tokens intro modal
  payWithTokensIntroModal: {
    title: '代币支付矿工费',
    feature_1_title: '无 ETH 也可交易',
    feature_1_desc: '结算费将会承担交易的所有费用',
    feature_2_title: '99%+ 成功率',
    feature_2_desc: '由机器人打包交易，确保每笔交易上链成功',
    feature_3_title: '闪兑保护',
    feature_3_desc: '使用任意钱包发送交易，都能免受 MEV',
    note: '注：卖出 ETH 时，此功能将会失效，<1>了解更多。</1>'
  },
  // permit2 intro modal
  permit2IntroModal: {
    title: 'Permit2 合约帮助你节省矿工费，并且更安全的进行交易',
    subtitle_1: '以下是 Permit2 的特点：',
    feature_1_key: '适用于任何代币:',
    feature_1: '应用程序可以通过将签名与交易数据一起发送来对任何代币进行交易，包括不支持原生授权方法的代币。',
    feature_2_key: '有时间限制的签名:',
    feature_2: '签名有时间限制，免除了将授权绑定在钱包的所有代币余额上的安全问题。',
    feature_3_key: '基于签名的转账:',
    feature_3: '用户可以通过一次性签名将代币发送给获得权限的花费者 (Spender)，不需要再额外设置授权。'
  },
  permit2ContractModal: {
    title: '授权交易额度',
    description_1: '此步骤无需矿工费，请在钱包进行确认。',
    description_2: '请不要修改授权设置，避免交易失败。',
    failed_description_1: '授权错误。',
    failed_description_2: '请勿修改授权设置，造成交易失败。'
  },
  submitParamsModal: {
    title: '执行交易',
    description_1: '你的订单已经准备就绪，请在你的钱包进行确认。',
    description_2: '',
    approving_text: '请确认签名',
    success_text: '签名成功',
    rejected_text: '签名失败'
  },
  toggleAllowanceModal: {
    approve_title: '授权 {{symbol}} 代币',
    lockdown_title: '取消授权 {{symbol}} 代币',
    description_1: '此步骤需要支付矿工费，',
    description_2: '请在你的钱包进行确认。'
  },
  // mini set pay mode modal
  miniSetPayModeModal: {
    title: 'ETH 不足以支付矿工费',
    desc: '打开「代币支付矿工费」模式，无需 ETH 也能进行交易'
  },
  setting_send_order_by_relayer_toast: '代币支付矿工费功能已开启',
  recent_trades: '最近交易',
  token_value: '代币价值',
  swap_chart_placeholder: '当前时间间隔数据不足或代币价格源暂未收录',
  free: '免费',
  limitOrderTradeFeeIntroModal: {
    title: '免费体验 Tokenlon 限价单',
    desc: 'Tokenlon 限价单将不会收取你的任何费用，包括挂单、完成或取消订单所需的矿工费，也不会收取任何交易手续费。'
  },
  lo_wrap_eth_tip: 'WETH 余额不足，请先使用「封装 ETH」工具将 ETH 转换为 WETH 后再挂单',
  lo_small_order_warning_content: '订单金额过低，无法覆盖网络费用，订单在达到挂单价时无法成交，建议你将交易金额提升至 <1>${{USDValue}}</1> 或以上。',
  lo_order_pending_tip: '因矿工费剧烈波动或未达到挂单价格，订单尚未成交，请耐心等待。',
  note: '温馨提示',
  adjust_amount: '调整金额',
  continue_to_trade: '继续交易',
  token_allowance_not_enough: '{{token}} 授权不足，需再次授权。',
  token_allowance_not_enough_and_need_cancel: '{{token}} 授权不足，请先取消授权后再次授权。',
  spotswap_max_btn_tooltip: '最大兑换数量已扣除交易所需的矿工费',
  spotswap_max_btn_tooltip_title: '最大兑换数量',
  copy_wc_uri: '复制到剪贴板',
  copy_wc_uri_success: '复制到剪贴板成功！',
  est_fast_sell_price: '预估快速成交价',
  est_fast_sell_price_tip: '根据网络情况预测，挂单价与快速成交价越接近，订单在短时间内成交的可能性越高。',
  est_execute_price: '预估成交价',
  est_execute_price_tip: '根据当下网络情况预估，该笔订单将会在市场价达到 {{executionRate}} 时成交。',
  in_progress: '进行中',
  summary: '概览',
  total: '总计',
  liquidity_pools: '流动性池',
  others: '其他',
  accumulating: '累计中',
  swap_protection_title: '闪兑保护',
  swap_protection_desc: '每笔在 Tokenlon 的交易都会得到保护，确保交易在有滑点发生的情况下，免受三明治夹击，保护用户利益。',
  back_to_swap: '返回闪兑',
  spotswap_successful_modal_desc: '每笔在 Tokenlon 的交易都会得到保护',
  review_btn: '预览订单',
  enter_amount_btn: '输入金额',
  fetching_quote_btn: '询价中',
  smart_routing: '智能路由',
  smart_routing_detail_modal_desc: '为了满足订单需求，考虑到矿工费成本、多跳以及拆单路径等多种因素，我们已经通过 {{protocols}} 为你找到最优报价。',
  spotswap_onboarding_modal_title: 'Tokenlon 6.0 抢先体验',
  spotswap_onboarding_modal_feature1: '交易不设限',
  spotswap_onboarding_modal_feature1_desc: '交易深度巨幅提升，满足大额交易需求',
  spotswap_onboarding_modal_feature2: '智能路由',
  spotswap_onboarding_modal_feature2_desc: '独家算法，为你提供全网最优报价',
  spotswap_onboarding_modal_feature3: '闪兑保护',
  spotswap_onboarding_modal_feature3_desc: '无需 ETH 也能交易并且免受 MEV',
  spotswap_onboarding_modal_btn: '立即体验',
  content_transition_desc: 'Tokenlon 6.0 加载中',
  header_notification: 'Tokenlon 6.0 最新版，尝鲜体验大额订单智能拆单，报价更优！如仍想使用旧版，',
  header_notification_btn: '点击返回 >',
  imbtc_notification: '比特币跨链网关服务已与 2024 年 1 月 31 日 12:00 (UTC+8) 下线。',
  connect_im_options_label: '适用于闪兑保护，',
  estimate_gas_failed_alert: '矿工费预估失败，请稍后重试',
  // DCA+
  best_buy: 'DCA+',
  best_buy_verify_min_amount_error: '目前每次买入价低于 {{amount}}U，可能不足支付矿工费，你可以选择',
  best_buy_verify_min_amount_error_tip_1: '降低「执行天数」',
  best_buy_verify_min_amount_error_tip_2: '提高「总额度」',
  best_buy_verify_max_amount_error: '超出最大额度 500,000',
  best_buy_verify_balance_occupied_error: '不可超过目前可用余额',
  best_buy_verify_max_expiration_error: '仅支援最多 1460 天',
  best_buy_status_pending: '进行中',
  best_buy_status_partialfilled: '进行中',
  best_buy_status_fulfilled: '已完成',
  best_buy_status_cancelled: '已取消',
  best_buy_status_expired: '已完成',
  best_buy_status_partialfillexpired: '已完成',
  best_buy_status_insufficient: '余额不足',
  best_buy_trade_status_success: '交易成功',
  best_buy_trade_status_no_trading_occurred: '未交易',
  best_buy_trade_status_tip_not_reached: '因未达触发价',
  best_buy_trade_status_tip_failed: '因上链发生异常所以未交易，別擔心，DCA+ 会在下次触达触发价时，补足未成交的金额',
  best_buy_trade_status_tip_failed_by_slippage: '因为市价波动太大造成滑价，为了保护你的资产，DCA+ 没有进行交易',
  best_buy_trade_status_insufficient: '余额不足导致扣款失败。',
  best_buy_trade_status_allowanceinsufficient: '授权额度不足',
  best_buy_onboarding_chart_title: 'Tokenlon DCA+ 侦测市场行情，智能分批购入',
  best_buy_onboarding_chart_desc: '紧跟市场节奏，指定触发价和总预算，捕获波动利基，优化成本效益！',
  best_buy_benefit_1: '免盯盘',
  best_buy_benefit_2: '控制预算',
  best_buy_benefit_3: '资金不离钱包',
  best_buy_benefit_4: '新手友好',
  best_buy_curve_chart_title: 'DCA+ 相较于手动抄底，能帮你用更便宜的价格更有纪律的买入',
  create_an_order: '建立订单',
  sell_token: '支付的代币',
  buy_token: '购买的代币',
  trigger_price: '触发价格',
  trigger_price_with_token: '只在 {{token}} 低于此价格时买入',
  reference: '参考选择',
  reference_tip: '订单买进时，若市价低于触发价 10% 以上，订单将全部成交。',
  market_price: '市价',
  total_amount: '购入总额度',
  duration: '执行天数',
  best_buy_preview_box_title: '根据上述信息',
  best_buy_preview_box_buy_when: ' 时买进',
  best_buy_preview_box_desc: 'DCA+ 智能策略可能会以比触发价更低的价格买进',
  best_buy_preview_box_info_duration: '持续 {{expirationValue}} {{expirationUnit}}',
  best_buy_preview_box_info_fee: '每次买进交易费为 0',
  best_buy_preview_box_info_budget: '总预算 {{amount}} {{token}}',
  best_buy_preview_box_info_other: '可以随时取消交易',
  modify_order: '修改订单',
  order_roi: '当前收益率',
  order_roi_tip: '收益率 = (当前价格 - 目前平均购入均价) / 目前平均购入均价 × 100%',
  order_time: '开始日期',
  remaining_duration: '剩余天数 / 总天数',
  order_executed_progress: '已投入金额 / 总额度',
  mini_amount_per_order: '每次最低买入金额',
  order_detail: '交易记录',
  order_detail_title: '{{inputToken}} 买入 {{outputToken}}',
  order_detail_purchase_price: '{{token}} 买入价',
  order_detail_amount: '买入金额',
  order_detail_received: '{{token}} 实收额',
  order_detail_fee: '交易费',
  blockchain_explorer: '区块浏览器',
  sign_best_buy_failed_1008: '{{inputTokenSymbol}} 余额已被活跃订单占用，请取消订单后重试',
  sign_best_buy_failed_1019: '使用者不在白名单中',
  sign_best_buy_failed_1020: 'The user’s token approval allowance is insufficient.',
  available_balance: '目前可用余额',
  relayer_gas_fee_tip: '已开启「代币支付矿工费」功能，结算费将会承担交易的所有费用。',
  settlement_fee: '结算费',
  gas_fee_risk_alert: '请注意！矿工费较高，约为 <special>{{percentage}}%</special> 的交易额。',
  settlement_fee_risk_alert: '当前兑换网络成本较高，结算费已约占 <special>{{percentage}}%</special> 交易额。',
  spotswap_swap_amount_too_low_alert: '交易额过低，无法覆盖兑换成本。请提高交易额或在设置里关闭「代币支付矿工费」功能以继续交易。',
  spotswap_swap_amount_too_low_btn: '兑换成本高于交易额',
  estimate_gas_fee: '预估矿工费',
  spotswap_estimate_gas_fee_tooltip: '矿工费会随网络拥堵情况以及拆单复杂性而实时变动。当前为预估值，以最终链上成交为准。',
  spotswap_swap_price_impact_too_high_alert: '价格影响过高 <bold>({{percentage}}%)</bold>，你将会损失价值约为 <bold>${{usdValue}}</bold> 的代币',
  spotswap_swap_price_impact_too_high_confirm: '我已知晓价格影响所带来的交易损失风险',
  spotswap_swap_price_impact_tooltip: '卖出和买入代币美元预估价值的差异'
};
export default zh_CN;